.side-menu-admin-nar {
  .ui.menu {
    width: 250px !important;
    overflow: hidden;
    padding-top: 4em;
    z-index: 99 !important;
    i.icon {
      float: initial !important;
      margin: 0 !important;
      margin-left: 10px !important;
      margin-right: 5px !important;
    }
  }

  .content {
    position: absolute;
    right: 0;
    padding: 20px;
    width: calc(100% - 250px);
  }
}
