.top-menu-admin {
  &__logo {
    width: 250px;
    background-color: #c4c4c4 !important;
  }

  i.icon {
    margin: 0 !important;
  }
}
